
import AvatarFlair from "@/components/templateux/user/avatar-flair";
import { ProfileTimer } from "./profile-timer";

export default function ProfileAvatar({
   isPlaying
  ,isContributor
  ,shrink=false
  ,start_date
  ,timestamp
  ,placeholder
  ,bol_caster=true
  ,avatar_url
  ,avatar_url_sfw
  ,bol_nsfw
  ,avatar_crdate
  ,id
  ,user_name
  ,isOwner=false
  ,hasData=true
}) {






  return (<>
    <div className={`flex flex-col items-center content-center  justify-center text-center w-10 h-16 relative  group
                    ${!hasData
                      ? '-mt-4'
                      : bol_caster 
                          ? isOwner
                            ? isPlaying 
                              ? '-mt-4'
                              : '-mt-8'
                            : '-mt-4'
                          : isOwner
                            ? '-mt-4'
                            : ''
                    }
                  `}>
      <div className="flex-0 w-[72px] h-16 relative ">
        <AvatarFlair 
            
            isPlaying={isPlaying }
            isContributor={isContributor}
            avatar_url={avatar_url}
            avatar_url_sfw={avatar_url_sfw}
            bol_nsfw={bol_nsfw}
            avatar_crdate={avatar_crdate}
            id={id}
            user_name={user_name}
            
            containerClass={`${shrink 
                                ? 'w-[64px] h-[32px]' 
                                : 'w-[72px] h-[36px]'
                              }`}
            sizes={{
                          width: shrink 
                                  ? 64 
                                  : 72
                          ,height: shrink 
                                  ? 64 
                                  : 72
                          ,quality: shrink 
                                  ? 75 
                                  : 100
                          }}
            bgClass={`${shrink 
                                  ? 'w-[64px] h-[64px]' 
                                  : 'w-[72px] h-[72px]'
                                }`}
            avatarClass={`border-4 border-transparent
                                ${shrink 
                                  ? 'w-[64px] h-[64px]' 
                                  : 'w-[72px] h-[72px]'
                                }`}
            rotaryContainerClass={`${shrink 
                                  ? 'w-[64px] h-[64px]' 
                                  : 'w-[72px] h-[72px]'
                                }`}
            rotaryInternalClass={`${shrink 
                                  ? 'w-[72px] h-[72px] -mr-[6px]' 
                                  : 'w-[80px] h-[80px] -mr-[8px]'
                                }`}
          />
      </div>
      {bol_caster &&
      <ProfileTimer
        shrink={shrink}
        isPlaying={isPlaying}
        start_date={start_date}
        timestamp={timestamp}
      />
      }

    </div>
</>)


}