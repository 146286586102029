
import { useEffect } from "react";
import { countUp } from "@/lib/utils/countUp";
import { hasData } from "@/lib/utils/hasData";
import useTimer from 'easytimer-react-hook';

export function ProfileTimer({
    shrink
    ,isPlaying
    ,start_date
    ,timestamp
}) {


    

    // const [timer, isTargetAchieved] = useTimer({
    //     /* Hook configuration */
    //     //startValues: countUp(start_date,timestamp)
    // });
    
    // useEffect(() => {
    //   if (hasData(start_date) && hasData(timestamp)) {
    
    //     if (isPlaying) {
    //       timer?.start({ 
    //             /* EasyTimer start configuration */
    //           startValues: countUp(start_date,timestamp)
    //       });
    //     }
    
    //   }
    // }, [
    //   start_date
    //   ,timestamp
    //   ,isPlaying
    //   ,timer]);

    return (<>
        <div className={` absolute cursor-pointer rounded-full text-2xs text-center whitespace-nowrap py-0.5 px-1 mx-auto min-w-min -mt-1 
                    ${shrink 
                      ? 'mt-[84px] w-[64px] -ml-2'
                      : 'mt-[100px] w-[72px] '}
                    ${isPlaying 
                      ? ' text-white gr bg-red-600  group-hover:bg-red-500' 
                      : `bg-gray-500 text-gray-200 group-hover:bg-gray-400 group-hover:text-white`
                    }`}
        >
                {isPlaying &&
                  <div className='flex items-center content-center'>
                    
                    <div className='flex-1 text-center  '>
                      LIVE
                    {/* {timer?.getTimeValues()?.toString(
                      parseInt(timer?.getTimeValues()?.toString(['days'])) > 0 
                      ? ['days','hours', 'minutes', 'seconds']
                      : ['hours', 'minutes', 'seconds']
                      )
                    } */}
                    </div>
                  </div>
                  }

                  {!isPlaying &&
                  <div className='flex items-center content-center'>
                    <div 
                      className='flex-1 px-2 '
                     
                    >
                        OFF AIR 
                    </div>
                    
                  </div>
                  }
      </div>
    </>)
}